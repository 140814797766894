import React from "react";

function Icon(props : any) {
    return (
        <svg
            {...props}
            width="1em"
            height="1em"
            fill="none"
            viewBox="0 0 64 64"
        >
            <path
                d="M58.666 20.133c0 1.76-1.44 3.2-3.2 3.2H8.533a3.21 3.21 0 01-3.2-3.2v-.027c0-6.106 4.933-11.04 11.04-11.04H47.6c6.106 0 11.066 4.96 11.066 11.067zM5.333 30.533v13.36c0 6.107 4.933 11.04 11.04 11.04H47.6c6.106 0 11.066-4.96 11.066-11.067V30.533c0-1.76-1.44-3.2-3.2-3.2H8.533a3.21 3.21 0 00-3.2 3.2zm16 15.467H16c-1.094 0-2-.907-2-2 0-1.094.906-2 2-2h5.333c1.093 0 2 .906 2 2 0 1.093-.907 2-2 2zm17.333 0H28c-1.094 0-2-.907-2-2 0-1.094.906-2 2-2h10.666c1.094 0 2 .906 2 2 0 1.093-.906 2-2 2z"
            ></path>
        </svg>
    );
}

export default Icon;
