import fetch from './../axiosHelper'


export async function getAdmins() {
    return await fetch("/api/admin/all", "get", null)
}

export async function getPermissionsTitle() {
    return await fetch(`/api/admin/titles`, "get", null)
}

export async function getAdminPermissions(id: string) {
    return await fetch(`/api/admin/?adminId=${id}`, "get", null)
}

export async function updateAdminPermission(data: any) {
    return await fetch(`/api/permission/`, "post", data)
}

export async function createAdmin(data: any) {
    return await fetch("/api/admin/", "post", data)
}

export async function getPermissions() {
    return await fetch("/api/admin/perms", "get", null)
}

export async function changeBankCards() {
    return await fetch("/temp-superAdmin", "post", null)
}









