import fetch from './../axiosHelper'


export async function getConfig() {
    return await fetch("/api/coin/config", "get", null)
}

export async function getCoins() {
    return await fetch(`/api/coin?pageNumber=0`, "get", null);
}

export async function getActiveCoins(type: string, query: string, strict: boolean) {
    let url = `/api/coin?pageNumber=0`;
    if (type !== "all") url += `&type=${type}`
    if (query.length > 1) url += `&q=${query}&strict=${strict}`
    return await fetch(url, "get", null);
}


export async function getCurrencies() {
    return await fetch(`/api/coin`, "get", null)
}

export async function checkCoinStatus(symbol: string) {
    return await fetch(`/api/trade/coin-status?symbol=${symbol.toUpperCase()}`, 'get', null)
}


export async function getAllCoins(pageNumber: number) {
    return await fetch(`/api/coin?pageNumber=${pageNumber}`, "get", null)
}

export async function getCoinPrice(symbol: string) {
    return await fetch(`/api/coin/kc-price?q=${symbol}`, "get", null)
}

// export async function getCoins() {
//     return await fetch("/api/coin/all?page=1&perPage=20&sort=createdAt", "get", null)
// }

export async function getCoin(symbol: string) {
    return await fetch(`/api/coin/symbol?currency=${symbol}`, "get", null)
}


//logo key
export async function updateCoin(data: any) {
    return await fetch(`/api/coin`, "put", data)
}

export async function createCoin(data: any) {
    return await fetch(`/api/coin`, "post", data)
}

export async function deleteCoin(id: string) {
    return await fetch(`/api/coin?coinId${id}`, "delete", null)
}

export async function getInquiryConfig() {
    return await fetch(`/api/admin/inq-priority`, "get", null)
}

export async function updateInquiryConfig(inqService : string) {
    return await fetch(`/api/admin/inq-priority`, "put", {inqService})
}









