import React, {useState} from "react";
import useLoading from "../hooks/useLoading";
import * as adminController from '../http/admins/adminController'
import Loading from "../components/utils/loading";
import {Outlet, useNavigate} from "react-router-dom";
import PermissionContext from './permissionContext'
import AppRoutes from "../constants/appRoutes";
import AppButton from "../components/utils/button/AppButton";
import Roles from "../constants/roles";
import Result from "../components/utils/result/Result";
import HelmetHelper from "../components/helmet/helmet";

export default function PermissionProvider() {
    const navigate = useNavigate();
    const [permissions, setPermissions] = useState<any>()
    const loading = useLoading(async () => {
        if (localStorage.getItem('role') === Roles.SUPER_ADMIN) {
            setSuperAdminPermissions()
        } else {
            await getPermissions()
        }
    }, [])

    const getPermissions = async () => {
        const res = await adminController.getPermissions();
        if (res.status) {
            if (res.permDoc && res.permDoc.permissions && res.permDoc.permissions.length > 0) {
                setPermissions({
                    isSuperAdmin: false,
                    permissions: res.permDoc.permissions
                })
            }
        }
    }

    const setSuperAdminPermissions = () => {
        setPermissions({
            isSuperAdmin: true,
        })
    }

    if (loading) {
        return <div className={'text-center'} style={{marginTop: '100px'}}>
            <Loading/>
        </div>
    } else if (permissions && !permissions.isSuperAdmin && permissions.permissions.length === 0) {
        return <div className={'text-center'} style={{marginTop: '100px'}}>
            <Result
                status="warning"
                title={<h4 className={'text-black bold f-large'}>بدون دسترسی</h4>}
                subTitle={<p className={'text-center text-white'}>همکار عزیز، دسترسی های شما تکمیل نشده است</p>}
                extra={<AppButton type={'btn-accent'} onClick={() => navigate(AppRoutes.LOGIN)}>
                    صفحه ورود
                </AppButton>}
            />
        </div>
    } else {
        return <PermissionContext.Provider value={{permissions}}>
            <HelmetHelper title={"ورسلند"}
                          description={"ورسلند، صرافی ارز دیجیتال با کمترین کارمزد و احراز هویت فوری در بستری امن و آسان برای خرید و فروش ارز دیجیتال"}
                          canonical={<link rel="canonical" href={`https://versland.io${AppRoutes.LANDING_PAGE}`}/>}
            />
            <Outlet/>
        </PermissionContext.Provider>
    }
}