import axios, {AxiosRequestConfig, Method} from 'axios';
import Storage from "../utils/storage";
import AppRoutes from "../constants/appRoutes";
import showServerError from "../utils/showErrorMessage";

// Define the interface for the elements in the failedQueue
interface FailedRequest {
    resolve: (value?: unknown) => void;
    reject: (reason?: any) => void;
    config: AxiosRequestConfig;
}


// Set Axios default configurations
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://api.versland.io';

// Queue to hold requests that need to be retried after the token is refreshed
// axios.defaults.withCredentials = true;

// Flag to track if a token refresh is in progress
let isRefreshing = false;
let failedQueue: FailedRequest[] = [];

// Function to process the queue of failed requests
function processQueue() {
    failedQueue.forEach((prom) => {
        axios.request(prom.config)
            .then((response) => prom.resolve(response))
            .catch((err) => prom.reject(err));
    });

    failedQueue = [];
}

// Axios request interceptor to add authentication headers
axios.interceptors.request.use((config) => {
    if (config.headers) {
        let token;
        if (config.url === '/api/auth/refresh' || config.url === '/api/auth/logout') token = Storage.getItem("refreshToken")
        else token = Storage.getItem("token")
        if (token)
            config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['Content-Type'] = `application/json`;
    }
    return config;
});

// Axios response interceptor to handle errors
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;

        if (!error.response) {
            return Promise.reject(error);
        }
        if (originalRequest.url === '/api/auth/refresh/' || originalRequest.url === '/api/auth/logout') {
            Storage.clear()
            window.location.replace(AppRoutes.LOGIN)
            return Promise.reject(error);
        }

        // Handle 401 Unauthorized errors
        if (error.response.status === 401 && !originalRequest._retry) {
            // If a token refresh is already in progress, add the request to the queue
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({resolve, reject, config: originalRequest});
                })
                    .then((token) => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }

            // Initiate a token refresh
            isRefreshing = true;

            return axios
                .post('/api/auth/refresh', {}, {
                    headers: {
                        'Authorization': Storage.getItem("refreshToken")
                    }
                })
                .then((res) => {
                    if (res.status === 200) {
                        Storage.setItem('token', res.data.accessToken)
                        processQueue();
                        originalRequest.headers['Authorization'] = 'Bearer ' + res?.data?.accessToken;
                    }
                    return axios(originalRequest);
                })
                .catch((err) => {
                    processQueue();
                    return Promise.reject(err);
                })
                .then(() => {
                    isRefreshing = false;
                });
        } else {
            if (error.message === 'Request failed with status code 429') {
                showServerError("Request failed with status code 429")
            } else {
                if (error.response.data.msg.includes('some inputs need to be unique:{\"mobile\":')) {
                    error.response.data.msg = "mobile is registered";
                } else if (error.response.data.msg.includes('some inputs need to be unique:{\"email\"')) {
                    error.response.data.msg = "email is registered";
                } else if (error.response.data.msg.includes('some inputs need to be unique:{\"nationalCode\"')) {
                    error.response.data.msg = "nationalCode is registered";
                } else if (error.response.data.msg.includes('some inputs need to be unique')) {
                    error.response.data.msg = "some inputs need to be unique";
                }
                showServerError(error.response.data.msg)
            }
        }
        return Promise.reject(error);
    }
);


export default async function fetch(url: string, method: Method, data: any) {
    if ((!data || data === "") || data === null) data = {}; //for get method
    const response = await axios({url: url, data: {...data}, method});
    return response.data
}
