import LogoutIcon from '../icons/logoutIcon'
import * as authController from '../../http/auth/authController'
import {useNavigate} from "react-router-dom";
import AppRoutes from "../../constants/appRoutes";

function Logout(props : any) {
    const {noText} = props
    const navigate = useNavigate()

    const logout = async () => {
        await authController.logout();
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('userId');
        localStorage.removeItem('birthdate');
        localStorage.removeItem('authLevel');
        localStorage.removeItem('name');
        localStorage.removeItem('fathername');
        localStorage.removeItem('lastname');
        localStorage.removeItem('firstname');
        localStorage.removeItem('refCode');
        localStorage.removeItem('certificationNum');
        localStorage.removeItem('nationalCode');
        localStorage.removeItem('mobile');
        navigate(AppRoutes.LOGIN, {replace: true})
    }
    return (
        <div onClick={logout} className='pointer flex flex-row'>
            <LogoutIcon/>
            {!noText && <span className='text-red-dark '>
                خروج از حساب کاربری
            </span> }

        </div>
    )

}

export default Logout
