enum Roles {

    USER = 'user',
    ADMIN = 'admin',
    SUPER_ADMIN = 'superAdmin' ,
    TRADER ='trader'

}

export default Roles
