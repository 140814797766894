import Status from "./Status";


interface ResultProps {
    status?: string,
    title?: JSX.Element,
    subTitle?: JSX.Element,
    extra?: JSX.Element
}


export default function Result(props: ResultProps) {
    const {status, title, subTitle, extra} = props;
    return (
        <div className={'mt-6'}>
            {status && <Status status={status}/>}
            {title && <div className={'text-center text-black mb-4'}>
                {title}
            </div>}
            {subTitle &&
                <div className={'text-center text-primary-light mb-4'}>
                    {subTitle}
                </div>
            }
            {extra && <div className={'mb-4 text-center'}>
                {extra}
            </div>}
        </div>
    )
}