import {Outlet, useLocation, useNavigate} from "react-router-dom";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import MenuIcon from './../../components/icons/menuIcon'
// @ts-ignore
import logo from '../../assets/images/logo/versland.png'
// @ts-ignore
import bit from '../../assets/images/logo/versland.png'
import AppRoutes from "../../constants/appRoutes";
import Logout from "../../components/utils/logout";
import AdminPanelHeader from "../../components/admin-panel/adminPanelHeader";
import Sider from "../../components/utils/sider/Sider";
import permissionContext from "../../contexts/permissionContext";
import adminMenuItem from "../../constants/menuItems/adminMenuItem";
import Menu from "../../components/utils/sider/Menu";
import AppDarwer, {Placement} from "../../components/utils/drawer/AppDrawer";
import UsersIcon from "../../components/icons/drawer/admin/users";
import TrxIcon from "../../components/icons/drawer/admin/dollarSignIcon";


function AdminLayout() {
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const onCollapse = (collapsed: any) => {
        setCollapsed(collapsed)
    }
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000)
    const handleClick = useCallback((key: string) => {
        navigate(key);
    }, []);

    useEffect(() => {
        let cb = function () {
            setIsMobile(window.innerWidth < 1000)
        };
        window.addEventListener("resize", cb);
        return () => {
            window.removeEventListener("resize", cb);
        };
    }, []);


    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    const {permissions} = useContext(permissionContext);
    const menu = useMemo(() => {
        if (localStorage.getItem('userId') === '63a00bb53648eab3bd5dad92') {
            return [
                {
                    key: 'user',
                    title: "کاربران",
                    link: AppRoutes.ADMIN_USERS,
                    icon: UsersIcon,
                    superAdmin: false,
                    showMenu: true,
                    showAll: false,
                    needPermission: "user"

                },
                {
                    key: 'transaction',
                    title: "تراکنش ها",
                    link: AppRoutes.ADMIN_TRANSACTION,
                    icon: TrxIcon,
                    superAdmin: false,
                    showAll: false,
                    showMenu: true,
                    needPermission: "transaction"
                },
            ]
        }
        if (permissions) {
            const menuList = adminMenuItem.filter((item: any) => item.showMenu)
            if (permissions?.isSuperAdmin) {
                return menuList
            } else {
                const menu: any[] = [];
                if (permissions?.permissions && permissions.permissions.length > 0) {
                    menuList.forEach((item: any) => {
                        if (!item.superAdmin && !item.showAll) {
                            const index = permissions.permissions.findIndex((perm: any) => {
                                return perm.title === item.needPermission && perm.power > 0
                            })
                            if (index >= 0) {
                                menu.push(item)
                            }
                        } else if (item.showAll) {
                            menu.push(item)
                        }
                    })
                }
                return menu
            }
        } else {
            return []
        }
    }, [permissions])

    return (
        <div className={'rtl admin-layout'}>
            {!isMobile
                ? <div className={'lg:flex md:flex sm:hidden xs:hidden  flex-row'}>
                    <Sider collapse={collapsed}>
                        <div
                            onClick={() => onCollapse(!collapsed)}
                            className={'pointer py-4'}>
                            <MenuIcon className={'fill-primary mx-auto'}/>
                        </div>
                        <Menu items={menu} onClick={handleClick} active={location.pathname} collapsed={collapsed}/>
                    </Sider>
                    <main className="flex-auto h-screen overflow-auto p-3">
                        <AdminPanelHeader/>
                        <Outlet/>
                    </main>
                </div>
                : <div className={'lg:hidden md:hidden sm:block xs:block '}>
                    <header className={'shadow page-padding'}>
                        <div style={{height: '48px'}} className={'flex-row flex items-center flex-wrap'}>
                            <div className={'col-8'}>
                                <MenuIcon className={'fill-black'}
                                          onClick={() => showDrawer()}/>
                            </div>
                            <div className={'col-8'}>
                                <h1 onClick={() => {
                                    handleClick(AppRoutes.LANDING_PAGE)
                                }} className={'text-center'}>
                                    <img
                                        loading={'lazy'}
                                        alt={'ورسلند'} className={"inline-block w-6 h-6 ml-2"} src={bit}/>
                                    ورسلند
                                </h1>
                            </div>
                            <div className={'col-8 text-left ltr'}>
                                <Logout noText={true}/>
                            </div>
                        </div>
                    </header>
                    <main className={'page-padding'}>
                        <AdminPanelHeader/>
                        <Outlet/>
                    </main>
                    <AppDarwer
                        placement={Placement.RIGHT}
                        onClose={onClose}
                        open={visible}
                    >
                        <div>
                            <div className={'text-center'}>
                                <img
                                    alt={'ورسلند'}
                                    loading={'lazy'}
                                    className={'pointer mx-auto'}
                                    onClick={() => navigate(AppRoutes.LANDING_PAGE)}
                                    height={'48px'}
                                    src={logo}/>
                            </div>
                            <div className={'text-center text-black'}>
                                ورسلند
                            </div>
                            <Menu items={menu} onClick={handleClick} active={location.pathname} collapsed={collapsed}/>
                        </div>
                    </AppDarwer>
                </div>
            }
        </div>
    )

}

export default AdminLayout;
