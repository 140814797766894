import AppRoutes from "../appRoutes";
import UsersIcon from "../../components/icons/drawer/admin/users";
import PeopleIcon from '../../components/icons/drawer/admin/peopleIcon'
import TrxIcon from '../../components/icons/drawer/admin/dollarSignIcon'
import CardIcon from '../../components/icons/drawer/admin/cardIcon'
import TicketIcon from '../../components/icons/drawer/admin/ticketIcon'
import DashboardIcon from '../../components/icons/drawer/dashboardIcon'
import GiftIcon from '../../components/icons/giftIcon'


const adminMenuItems = [
    {
        key: 'dashboard',
        title: "داشبورد",
        link: AppRoutes.ADMIN_DASHBOARD,
        icon: DashboardIcon,
        superAdmin: true,
        showMenu: true,
        showAll: false,
        needPermission: []
    },
    {
        key: 'user',
        title: "کاربران",
        link: AppRoutes.ADMIN_USERS,
        icon: UsersIcon,
        superAdmin: false,
        showMenu: true,
        showAll: false,
        needPermission: "user"

    },
    {
        key: 'webContent',
        title: 'محتوا',
        link: AppRoutes.ADMIN_CONTENT,
        icon: DashboardIcon,
        superAdmin: false,
        showMenu: true,
        showAll: false,
        needPermission: "webContent"
    },
    {
        key: 'coin',
        title: 'مدیریت ارزها',
        link: AppRoutes.ADMIN_COINS,
        icon: TrxIcon,
        superAdmin: false,
        showMenu: true,
        showAll: false,
        needPermission: "coin"

    },
    {
        key: 'admin',
        title: "همکاران",
        link: AppRoutes.ADMINS,
        icon: PeopleIcon,
        superAdmin: true,
        showMenu: true,
        showAll: false,
        needPermission: []
    }
    ,
    {
        key: 'ticket',
        title: "تیکت ها",
        link: AppRoutes.ADMIN_TICKET,
        icon: TicketIcon,
        superAdmin: false,
        showMenu: true,
        showAll: false,
        needPermission: "ticket"

    },
    {
        key: 'card',
        title: "کارت های بانکی",
        link: AppRoutes.ADMIN_CARDS,
        icon: CardIcon,
        superAdmin: false,
        showMenu: true,
        showAll: false,
        needPermission: "card"
    },
    {
        key: 'transaction',
        title: "تراکنش ها",
        link: AppRoutes.ADMIN_TRANSACTION,
        icon: TrxIcon,
        superAdmin: false,
        showAll: false,
        showMenu: true,
        needPermission: "transaction"
    },
    {
        key: 'withdraw',
        title: "درخواست های برداشت",
        link: AppRoutes.ADMIN_WITHDRAWAL_TRANSACTION,
        icon: TrxIcon,
        superAdmin: false,
        showAll: false,
        showMenu: true,
        needPermission: "withdraw"

    },
    {
        key: 'deposit',
        title: "واریزی های ارزی",
        link: AppRoutes.ADMIN_DEPOSIT_TRANSACTION,
        icon: TrxIcon,
        superAdmin: false,
        showAll: false,
        showMenu: true,
        needPermission: "transaction"
    },
    {
        key: 'deposit-irt',
        title: "واریزی های تومان",
        link: AppRoutes.ADMIN_DEPOSIT_IRT_TRANSACTION,
        icon: TrxIcon,
        superAdmin: false,
        showAll: false,
        showMenu: true,
        needPermission: "transaction"

    },
    {
        key: 'futures',
        title: "تعهدی",
        link: AppRoutes.ADMIN_FUTURES,
        icon: TrxIcon,
        superAdmin: true,
        showMenu: true,
        showAll: false,
        needPermission: "transaction"
    } ,
    {
        key: 'accountancy',
        title: "حسابداری",
        link: AppRoutes.ADMIN_ACCOUNTANCY,
        icon: TrxIcon,
        superAdmin: true,
        showMenu: true,
        showAll: false,
        needPermission: []

    },
    {
        key: 'kucoin',
        title: "اطلاعات کوکوین",
        link: AppRoutes.ADMIN_KUCOIN,
        icon: TrxIcon,
        superAdmin: false,
        showAll: false,
        showMenu: true,
        needPermission: "kucoin"
    },
    {
        key: 'copyTrade',
        title: "تریدر",
        link: AppRoutes.ADMIN_TRADER,
        icon: TrxIcon,
        superAdmin: false,
        showMenu: true,
        showAll: false,
        needPermission: "copyTrade"

    },
    {
        key: 'old-copy-trade',
        title: "کپی تریدینگ",
        link: AppRoutes.ADMIN_INVSET,
        icon: TrxIcon,
        superAdmin: true,
        showAll: true,
        showMenu: true,
        needPermission: "copyTrade"

    },

    {
        key: 'reward',
        title: "هدیه",
        link: AppRoutes.ADMIN_REWARD,
        icon: GiftIcon,
        superAdmin: false,
        showAll: false,
        showMenu: true,
        needPermission: "transaction"
    },
    {
        key: 'security',
        title: "امنیت",
        link: AppRoutes.ADMIN_SECURITY,
        icon: TrxIcon,
        superAdmin: false,
        showAll: true,
        showMenu: true,
        needPermission: "kucoin"
    },
    {
        key: 'user-profile',
        title: "پروفایل کاربر",
        link: AppRoutes.ADMIN_USER,
        icon: TrxIcon,
        superAdmin: false,
        showMenu: false,
        showAll: false,
        needPermission: ["user", "card", "transaction"]
    },
    {
        key: 'trader-transactions',
        title: "تراکنش های تریدر",
        link: AppRoutes.ADMIN_TRADER_TRANSACTION,
        icon: TrxIcon,
        superAdmin: false,
        showMenu: false,
        showAll: false,
        needPermission: ["copyTrade"]
    },
    {
        key: 'ticket-detail',
        title: "جزئیات تیکت",
        link: AppRoutes.ADMIN_TICKET_DETAIL,
        icon: TrxIcon,
        superAdmin: false,
        showMenu: false,
        showAll: false,
        needPermission: ["ticket"]
    },
]

export default adminMenuItems
