import React from 'react'
import Result from "./result/Result";

export default class ErrorBoundary extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error: any) {
        // It will update the state so the next render shows the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error: any, info: any) {
        // It will catch error in any component below. We can also log the error to an error reporting service.
        console.log(info, error);
        alert(error.message)
    }

    render() {
        // @ts-ignore
        if (this.state.hasError) {
            return (
                <Result
                    status="500"
                    title={<h5 className={'bold f-large'}>500</h5>}
                    subTitle={<p className={'text-center'}>خطایی رخ داده است! لطفا به پشتیبانی خبر دهید</p>}
                    extra={<a href={'https://versland.io'} >
                        صفحه اصلی
                    </a>}
                />);
        }
        return this.props.children;
    }
}
