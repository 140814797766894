// @ts-ignore
import appIcon from '../../assets/images/logo/versland_64.png'

function Loading() {
    return (
        <div className={'my-28'}>
            <div className={'text-center app-loading'}>
                <span className={'inline-block rounded-md p-2 mx-auto bg-slate'}>
                    <img alt={'ورسلند'} src={appIcon} className={'w-8 h-8 app-icon'}/>
                </span>
            </div>
        </div>
    )
}

export default Loading;

