import classNames from "classnames";
import AppRoutes from "../../../constants/appRoutes";
// @ts-ignore
import logo from '../../../assets/images/logo/versland.png'

interface MenuItem {
    icon: any,
    link: string,
    title: string
}

interface MenuProps {
    items: MenuItem[],
    active: string,
    collapsed: boolean,

    onClick(link: string): void
}

export default function Menu(props: MenuProps) {
    const {items, active, collapsed, onClick} = props;
    return (
        <ul className={'list-none m-0 p-0'}>
            <li key={AppRoutes.LANDING_PAGE}
                className={classNames('select-none py-2 pointer flex flex-row gap-x-2 items-center',
                    {'justify-center pr-0': collapsed},
                    {'justify-start pr-2': !collapsed},
                    {'bg-slate-300 border-r-4 border-solid border-accent': active === AppRoutes.LANDING_PAGE},
                    {'bg-white border-r-4 border-solid border-white': active !== AppRoutes.LANDING_PAGE},
                )}
                onClick={() => onClick(AppRoutes.LANDING_PAGE)}>
                <img src={logo} alt={'ورسلند'} className={'w-4 h-4'}/>

                <span
                    className={classNames('text-primary-light whitespace-nowrap f-small overflow-hidden transition-all duration-300 delay-500', {'hidden': collapsed}, {'inline-block transition-all duration-300 delay-500': !collapsed})}>صفحه اصلی</span>
            </li>
            {items.map((item: MenuItem) => {
                const Icon = item.icon;
                return (
                    <li key={item.link}
                        className={classNames('select-none py-2 pointer flex flex-row gap-x-2 items-center',
                            {'justify-center pr-0': collapsed},
                            {'justify-start pr-2': !collapsed},
                            {'bg-slate-300 border-r-4 border-solid border-accent': active === item.link},
                            {'bg-white border-r-4 border-solid border-white': active !== item.link},
                        )}
                        onClick={() => onClick(item.link)}>
                        <Icon
                            className={classNames({'fill-accent': active === item.link}, {'fill-primary-light': active !== item.link})}/>
                        <span
                            className={classNames('text-primary-light whitespace-nowrap f-small overflow-hidden transition-all duration-300 delay-500', {'hidden': collapsed}, {'inline-block transition-all duration-300 delay-500': !collapsed})}>{item.title}</span>
                    </li>
                )
            })}
        </ul>
    )

}