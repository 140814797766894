import React from "react";

function Icon(props : any) {
    return (
        <svg
            {...props}
            width="1em"
            height="1em"
            fill="none"
            viewBox="0 0 54 54"
        >
            <path
                d="M26.92.066C12.2.12.28 12.093.333 26.813.387 41.533 12.36 53.453 27.08 53.4c14.72-.053 26.64-12.027 26.587-26.747C53.612 11.933 41.64.04 26.92.066zM33.026 27c2.08.72 4.88 2.267 4.88 7.04 0 4.107-3.226 7.413-7.173 7.413H29V43c0 1.093-.907 2-2 2s-2-.907-2-2v-1.547h-.96c-4.373 0-7.92-3.68-7.92-8.213 0-1.093.906-2 2-2 1.093 0 2 .907 2 2 0 2.32 1.76 4.213 3.92 4.213H25V28.44L20.973 27c-2.08-.72-4.88-2.267-4.88-7.04 0-4.107 3.227-7.414 7.174-7.414H25V11c0-1.094.907-2 2-2s2 .906 2 2v1.546h.96c4.373 0 7.92 3.68 7.92 8.214 0 1.093-.907 2-2 2-1.094 0-2-.907-2-2 0-2.32-1.76-4.213-3.92-4.213H29v9.013L33.026 27z"
            ></path>
        </svg>
    );
}

export default Icon;
