import Logout from "../utils/logout";
import {useEffect} from "react";

function AdminPanelHeader() {
    return (
        <div
            className={'flex flex-row items-center md:border-b border-solid border-slate-300 md:pb-3 sm:py-2  xs:py-2  mb-4 '}>
            <div className={'lg:col-8 md:col-8 sm:col-12 xs:col-12'}>
                <h5 className={'text-black text-right'}>
                    پنل ادمین ورسلند
                </h5>
            </div>
            <div className={'lg:col-8 md:col-8 sm:col-12 xs:col-12'}>
                <h5 className={'md:text-center xs:text-left sm:text-left text-black'}>
                    {localStorage.getItem('name')}
                </h5>
            </div>
            <div className={'lg:col-8 md:col-8 sm:col-0 xs:col-0 ltr'}>
                <Logout noText={true}/>
            </div>
        </div>
    )
}

export default AdminPanelHeader
