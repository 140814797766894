class AppStorage {
    // Get an item from local localStorage and parse it as JSON
    static getItem(key: string, initialValue?: any) {
        if (typeof window !== "undefined") {
            const value: any = window.localStorage.getItem(key);
            if (value) {
                try {
                    return JSON.parse(value);
                } catch (error) {
                    return value;
                }
            } else {
                if (initialValue)
                    return initialValue
                else return ""
            }
        }
    }

    // Set an item in local localStorage by stringifying it as JSON
    static setItem(key: string, value: any) {
        if (typeof window !== "undefined") {
            if (typeof value === "string") {
                window.localStorage.setItem(key, value);
            } else {
                const jsonValue = JSON.stringify(value);
                window.localStorage.setItem(key, jsonValue);
            }
        }
    }

    // Remove an item from local localStorage
    static removeItem(key: string) {
        if (typeof window !== "undefined") {
            window.localStorage.removeItem(key);
        }
    }

    static clear() {
        if (typeof window !== "undefined") {
            window.localStorage.clear();
        }
    }
}

export default AppStorage;