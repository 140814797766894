import React from "react";
// @ts-ignore
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App/>
);


// console.log("service worker ... ")
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//     onUpdate: registration => {
//         console.log("new version of application ...")
//         if (registration && registration.waiting) {
//             registration.waiting.postMessage({type: 'SKIP_WAITING'});
//         }
//         window.location.reload();
//     }
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
