import {Navigate, Outlet, useLocation} from "react-router-dom";
import AppRoutes from "../constants/appRoutes";
import Roles from "../constants/roles";
import {useState} from "react";


export default function AuthLayout() {
    const location = useLocation()
    const [token] = useState(localStorage.getItem('token'));
    const [role] = useState(String(localStorage.getItem('role')));
    if (!token) {
        return <Navigate to={AppRoutes.LOGIN} state={{from: location}} replace={true}/>
    }

    if (role === Roles.USER && !location.pathname.includes('user')) {
        return <Navigate to={AppRoutes.LOGIN} state={{from: location}} replace={true}/>
    } else if (role?.includes('admin') && !location.pathname.includes('admin')) {
        return <Navigate to={AppRoutes.LOGIN} state={{from: location}} replace={true}/>
    } else if (role === Roles.TRADER && !location.pathname.includes('trader')) {
        return <Navigate to={AppRoutes.LOGIN} state={{from: location}} replace={true}/>
    } else {
        return <Outlet/>
    }
}