import React from "react";
import "./assets/styles/App.scss";
import {BrowserRouter} from "react-router-dom";
import RouteManager from "./RouteManager";
import ErrorBoundary from "./components/utils/errorBoundary";
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient()

function App() {
    return (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <div className={'rtl'}>
                    <BrowserRouter>
                        <RouteManager/>
                    </BrowserRouter>
                </div>
                {/*<ReactQueryDevtools initialIsOpen={false} />*/}
            </QueryClientProvider>
        </ErrorBoundary>
    );

}

export default App;
