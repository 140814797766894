import React from "react";

function Icon(props : any) {
    return (
        <svg
            {...props}
            width="1em"
            height="1em"
            fill="none"
            viewBox="0 0 54 46"
        >
            <path
                d="M40.333.333H13.666c-8 0-13.333 4-13.333 13.333v18.667c0 9.333 5.333 13.333 13.333 13.333h26.667c8 0 13.333-4 13.333-13.333V13.666c0-9.333-5.333-13.333-13.333-13.333zm1.253 16.24L33.24 23.24c-1.76 1.413-4 2.106-6.24 2.106s-4.507-.693-6.24-2.106l-8.347-6.667a2.052 2.052 0 01-.32-2.827c.693-.853 1.947-1.013 2.8-.32l8.347 6.667c2.026 1.627 5.466 1.627 7.493 0l8.347-6.667c.853-.693 2.133-.56 2.8.32.693.854.56 2.134-.294 2.827z"
            ></path>
        </svg>
    );
}

export default Icon;
