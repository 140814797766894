import CoinContext from './coinContext'
import {useState} from "react";
import {getCurrencies} from "../http/coin/coinController";
import {Outlet} from "react-router-dom";
import useLoading from "../hooks/useLoading";

export default function CoinProvider() {
    const [currencies, setCurrencies] = useState<any[]>([]);
    const currencyLoading = useLoading(async () => {
        await getCoins()
    }, [])

    const getCoins = async () => {
        const res = await getCurrencies()
        if (res.status) {
            setCurrencies(res.coins)
        }
    }
    return (
        <CoinContext.Provider value={{
            currencyLoading ,
            currencies
        }}>
            {/*{currencies && currencies.length > 0 ? <Outlet/> : <Row className={"vh-100"} justify={'center'} align={'middle'}>*/}
            {/*    <Col span={12} className={'text-center'}>*/}
            {/*        <Spin/>*/}
            {/*    </Col>*/}
            {/*</Row>}*/}
            <Outlet/>
        </CoinContext.Provider>
    )
}