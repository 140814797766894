import fetch from './../axiosHelper'
import RegisterModel from "../../domain/model/auth/registerModel";


export async function login(data: any) {
    return await fetch("/api/auth/login", "post", data)
}

export async function register(data: RegisterModel) {
    return await fetch("/api/auth/register", "post", data)
}

export async function requestOtpForRegister(mobile: number) {
    return await fetch("/api/auth/register-otp", "post", {mobile})
}

export async function sendVerificationEmail(data: any) {
    return await fetch("/api/auth/send-verification-email", "post", data)
}

export async function forgetPassword(data: any) {
    return await fetch("/api/auth/forgot-password", "post", data)
}

export async function resetPassword(data: any) {
    return await fetch("/api/auth/reset-password", "post", data)
}

export async function updatePassword(data: any) {
    return await fetch("/api/auth/update-password", "post", data)
}

export async function logout() {
    return await fetch("/api/auth/logout", "delete", null)
}

export async function sendOtp(mobile: number, nationalCode: string) {
    return await fetch("/api/auth/create-otp", "post", {mobile, nationalCode})
}

export async function sendOtpForWithdraw(card: number) {
    return await fetch("/api/auth/create-otp-withdrawal", "post", {card})
}

export async function sendOtpForWithdrawCoin() {
    return await fetch("/api/auth/create-otp-withdrawal-coin", "post", {})
}

export async function getActiveTokens() {
    return await fetch("/api/auth/device", "get", {})
}

export async function deleteToken(id: string) {
    return await fetch(`/api/auth/device?tokenId=${id}`, "delete", {})
}





