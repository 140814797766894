import React from "react";

function Icon(props : any) {
    return (
        <svg
            {...props}
            width="1em"
            height="1em"
            fill="none"
            viewBox="0 0 64 64"
        >
            <path
                d="M46.747 20.72a1.968 1.968 0 00-.56 0c-4.134-.134-7.414-3.52-7.414-7.68 0-4.24 3.44-7.707 7.707-7.707 4.24 0 7.707 3.44 7.707 7.707-.027 4.16-3.307 7.546-7.44 7.68zM55.44 39.2c-2.987 2-7.173 2.747-11.04 2.24 1.013-2.187 1.547-4.613 1.573-7.173 0-2.667-.586-5.2-1.706-7.414 3.946-.533 8.133.214 11.146 2.214 4.214 2.773 4.214 7.333.027 10.133zM17.173 20.72c.187-.027.373-.027.56 0 4.133-.134 7.413-3.52 7.413-7.68a7.693 7.693 0 00-7.706-7.707c-4.24 0-7.68 3.44-7.68 7.707 0 4.16 3.28 7.546 7.413 7.68zM17.467 34.266c0 2.587.56 5.04 1.573 7.254-3.76.4-7.68-.4-10.56-2.294-4.213-2.8-4.213-7.36 0-10.16 2.854-1.92 6.88-2.693 10.667-2.266-1.093 2.24-1.68 4.773-1.68 7.466zM32.32 42.32a3.008 3.008 0 00-.694 0c-4.906-.16-8.826-4.187-8.826-9.147C22.826 28.107 26.907 24 32 24a9.173 9.173 0 019.173 9.173c-.026 4.96-3.92 8.987-8.853 9.147zM23.654 47.84c-4.027 2.693-4.027 7.12 0 9.787 4.587 3.066 12.107 3.066 16.693 0 4.027-2.694 4.027-7.12 0-9.787-4.56-3.067-12.08-3.067-16.693 0z"
            ></path>
        </svg>
    );
}

export default Icon;
