import React, {Suspense} from "react";
import Loading from "./components/utils/loading";
import Row from "./components/utils/flex/Row";

const SuspenseWrapper = (props: any) => {
    return (
        <Suspense
            fallback={
                <Row justify={'center'} align={'center'}
                     className={'z-50 bg-white fixed top-0 left-0 right-0 bottom-0'}>
                    <Loading/>
                </Row>
            }
        >
            {props.children}
        </Suspense>
    );
};
export default SuspenseWrapper;