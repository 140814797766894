import React from "react";

function Icon(props : any) {
    return (
        <svg
            {...props}
            width="1.3em"
            height="1.3em"
            fill="none"
            viewBox="0 0 24 17"
            aria-hidden={true}
        >
            <rect width="24" height="3" fill="#0A2741" rx="1"></rect>
            <rect width="24" height="3" y="7" fill="#0A2741" rx="1"></rect>
            <rect width="24" height="3" y="14" fill="#0A2741" rx="1"></rect>
        </svg>
    );
}

export default Icon;
