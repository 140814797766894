import React from "react";

function Icon(props: any) {
    return (
        <svg
            {...props}
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 64 64"
        >
            <path
                d="M42.586 60.667H21.253c-9.12 0-12.667-3.547-12.667-12.667V26.667c0-1.094.907-2 2-2h42.667c1.093 0 2 .906 2 2V48c0 9.12-3.547 12.667-12.667 12.667zm-30-32V48c0 6.88 1.787 8.667 8.667 8.667h21.333c6.88 0 8.667-1.787 8.667-8.667V28.667H12.586z"
            ></path>
            <path
                d="M52 28.667H12c-4.667 0-7.334-2.667-7.334-7.334v-2.666c0-4.667 2.667-7.334 7.333-7.334h40c4.534 0 7.334 2.8 7.334 7.334v2.666c0 4.534-2.8 7.334-7.334 7.334zM12 15.333c-2.427 0-3.334.907-3.334 3.334v2.666c0 2.427.907 3.334 3.333 3.334h40c2.347 0 3.334-.987 3.334-3.334v-2.666c0-2.347-.987-3.334-3.334-3.334H12z"
            ></path>
            <path
                d="M31.04 15.333H16.32c-.56 0-1.092-.24-1.466-.64-1.626-1.786-1.573-4.533.134-6.24l3.786-3.786c1.76-1.76 4.667-1.76 6.427 0l7.253 7.253c.56.56.747 1.44.427 2.187-.293.746-1.013 1.226-1.84 1.226zm-13.252-4h8.453l-3.867-3.84a.547.547 0 00-.773 0l-3.787 3.787c0 .027-.026.027-.026.053z"
            ></path>
            <path
                d="M47.654 15.333h-14.72c-.8 0-1.547-.48-1.84-1.226-.32-.747-.134-1.6.426-2.187l7.254-7.253c1.76-1.76 4.666-1.76 6.426 0l3.787 3.786a4.494 4.494 0 01.133 6.24c-.373.4-.906.64-1.466.64zm-9.867-4h8.453l-.053-.053L42.4 7.493a.547.547 0 00-.773 0l-3.84 3.84zM26.506 45.067A4.673 4.673 0 0121.84 40.4V26.667c0-1.094.908-2 2-2h16.108c1.093 0 2 .906 2 2v13.68c0 1.733-.934 3.306-2.454 4.106-1.52.827-3.36.72-4.8-.24l-2.373-1.6c-.214-.16-.507-.16-.747 0l-2.506 1.654c-.774.533-1.68.8-2.56.8zm-.666-16.4v11.706c0 .347.213.507.346.587.134.08.4.16.694-.027l2.507-1.653a4.635 4.635 0 015.146 0l2.373 1.6c.294.187.56.107.694.027.133-.08.346-.24.346-.587V28.64H25.84v.027z"
            ></path>
        </svg>
    );
}

export default Icon;
