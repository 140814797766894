import React from "react";

function Icon(props: any) {
    return (
        <svg
            {...props}
            width="1em"
            height="1em"
            fill="none"
            viewBox="0 0 28 28"
            aria-hidden={"true"}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M25.667 12.717V4.783c0-1.75-.747-2.45-2.602-2.45h-4.713c-1.855 0-2.602.7-2.602 2.45v7.934c0 1.75.747 2.45 2.602 2.45h4.713c1.855 0 2.602-.7 2.602-2.45zM25.667 23.217v-2.1c0-1.75-.747-2.45-2.602-2.45h-4.713c-1.855 0-2.602.7-2.602 2.45v2.1c0 1.75.747 2.45 2.602 2.45h4.713c1.855 0 2.602-.7 2.602-2.45zM12.25 15.283v7.934c0 1.75-.747 2.45-2.602 2.45H4.935c-1.855 0-2.602-.7-2.602-2.45v-7.934c0-1.75.747-2.45 2.602-2.45h4.713c1.855 0 2.602.7 2.602 2.45zM12.25 4.783v2.1c0 1.75-.747 2.45-2.602 2.45H4.935c-1.855 0-2.602-.7-2.602-2.45v-2.1c0-1.75.747-2.45 2.602-2.45h4.713c1.855 0 2.602.7 2.602 2.45z"
            ></path>
        </svg>
    );
}

export default Icon;
