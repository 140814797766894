import {io} from "socket.io-client";

const baseUrl: string = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';

export const initializeSocket = (query?: any) => {
    if (query) {
        return io(baseUrl, {
            query: query ,
              transports : ['websocket' , 'polling']
        });
    } else {
        return io(baseUrl, {
              transports : ['websocket' , 'polling']
        });
    }
};

//enums
export enum SocketEventsEnum {
    ALL_PRICES = "allPrices",
    SPOT_TRADE = "kc:orderbook",
    LIVE_PRICE = "kc:liveprice",
    MARKET_SNAPSHOT = "kc:market-snapshot",
    USER_ORDERS = "versland:orderbook",

}


