import React from "react";

function Icon(props : any) {
    return (
        <svg
            {...props}
            width="1em"
            height="1em"
            fill="none"
            viewBox="0 0 48 54"
        >
            <path
                d="M40 .333H8c-4.427 0-8 3.547-8 7.92v29.093c0 4.374 3.573 7.947 8 7.947h2.027c2.106 0 4.16.827 5.653 2.32l4.56 4.507a5.36 5.36 0 007.52 0l4.56-4.507c1.493-1.493 3.547-2.32 5.653-2.32H40c4.427 0 8-3.573 8-7.947V8.253c0-4.373-3.573-7.92-8-7.92zM24 9.8c2.88 0 5.2 2.346 5.2 5.2 0 2.826-2.24 5.093-5.013 5.2h-.4a5.187 5.187 0 01-5.014-5.2C18.8 12.146 21.12 9.8 24 9.8zm7.333 24.373c-4.026 2.693-10.64 2.693-14.666 0-3.547-2.347-3.547-6.24 0-8.613 4.053-2.694 10.666-2.694 14.666 0 3.547 2.373 3.547 6.24 0 8.613z"
            ></path>
        </svg>
    );
}

export default Icon;
