// @ts-ignore
import {Helmet} from 'react-helmet'
import React from "react";

export default function HelmetHelper(
    {title, description, meta, canonical}:
        { title: string, description: string, meta?: any, canonical?: any }) {
    // const Canonical = canonical;
    return (
        <Helmet>
            <title>{title}</title>
            <meta
                name="description"
                content={description}
            />
            {/*<link rel="canonical" href="https://versland.io/about-us" />*/}
            {meta}
            {canonical}
        </Helmet>
    )

}
