
import Success from "./Success";
import Error from "./Error";
import Warning from "./Warning";
import Info from "./info";
import InternalError from "./InternalError";
import NotFound from "./NotFound";
enum ResultStatus {
    SUCCESS = 'success',
    WARNING = "warning",
    ERROR = 'error',
    INFO = 'info',
    NOT_FOUND = '404',
    INTERNAL = '500',
}

const Status = ({status}: { status: string }) => {
    switch (status) {
        case ResultStatus.SUCCESS :
            return <div className={'mb-4'}>
                <Success/>
            </div>
        case ResultStatus.ERROR :
            return <div className={'mb-4'}>
                <Error/>
            </div>
        case ResultStatus.WARNING :
            return <div className={'mb-4'}>
                <Warning/>
            </div>
        case ResultStatus.INFO :
            return <div className={'mb-4'}>
                <Info/>
            </div>
        case ResultStatus.NOT_FOUND :
            return <div className={'mb-4'}>
               <NotFound/>
            </div>
        case ResultStatus.INTERNAL :
            return <div className={'mb-4'}>
                <InternalError/>
            </div>
        default:
            return <div className={'mb-4'}>
                <svg className={'w-8 h-8 mx-auto'} viewBox="64 64 896 896" focusable="false"
                     data-icon="exclamation-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path
                        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path>
                </svg>
            </div>

    }
}
export default Status