import {useEffect, useLayoutEffect, useState} from "react";
// import {initializeSocket, SocketEventsEnum} from "../socket/socketHelper";
// import {Socket} from "socket.io-client";
import PriceContext from './priceContext';
import * as paymentController from "../http/payment/paymentController";
import {Outlet} from "react-router-dom";
import useInterval from "../hooks/utils/useInterval";
import axios from "axios";
import {SocketEventsEnum} from "../socket/socketHelper";

// let tempSocket = initializeSocket();

function PriceProvider() {

    const [loadingPayConf, setLoadingPayConf] = useState(true);
    const [allPrices, setAllPrices] = useState<any[]>(() => {
        const prices: any = localStorage.getItem(SocketEventsEnum.ALL_PRICES);
        if (prices) return JSON.parse(prices);
        else return []
    });

    const [depositIRTEnable, setDepositIrtEnable] = useState(true);
    const [withdrawIRTEnable, setWithdrawIRTEnable] = useState(true);
    const [depositIRTMax, setDepositIRTMax] = useState(100000000)
    const [depositIRTMin, setDepositIrtMin] = useState(10000)
    const [withdrawIRTMax, setWithdrawIRTMax] = useState(20000000)
    const [withdrawIRTMin, setWithdrawIRTMin] = useState(50000)
    const [tradeMaxIRT, setTradeMaxIRT] = useState(100000000)
    const [tradeMinIRT, setTradeMinIRT] = useState(10000)
    const [tetherBuyPrice, setTetherBuyPrice] = useState(30000);
    const [tetherSellPrice, setTetherSellPrice] = useState(28000);
    const [feePercent, setFeePercent] = useState<number>(0);
    const [feePercentSpot, setFeePercentSpot] = useState<number>(0);
    const [spotTradeMaxUsdt, setSpotTradeMaxUsdt] = useState(100)
    const [spotTradeMinUsdt, setSpotTradeMinUsdt] = useState(1000)
    const [investFeePercent, setInvestFeePercent] = useState<number>(0);
    useLayoutEffect(() => {
        getPaymentConfig();
    }, [])
    const getPaymentConfig = async () => {
        const res = await paymentController.getConfig()
        if (res.status) {
            setFeePercent(res.paymentConf.feePercent)
            setInvestFeePercent(res.paymentConf.investFeePercent)
            setTetherBuyPrice(res.paymentConf.tetherBuyPrice)
            setTetherSellPrice(res.paymentConf.tetherSellPrice)
            setDepositIrtEnable(res.paymentConf.depositIRTEnable)
            setDepositIRTMax(res.paymentConf.depositIRTMax)
            setDepositIrtMin(res.paymentConf.depositIRTMin)
            setTradeMaxIRT(res.paymentConf.tradeMaxIRT)
            setTradeMinIRT(res.paymentConf.tradeMinIRT)
            setWithdrawIRTEnable(res.paymentConf.withdrawIRTEnable)
            setWithdrawIRTMax(res.paymentConf.withdrawIRTMax)
            setWithdrawIRTMin(res.paymentConf.withdrawIRTMin)
            setFeePercentSpot(res.paymentConf.feePercentSpot)
            setSpotTradeMaxUsdt(res.paymentConf.spotTradeMaxUSDT)
            setSpotTradeMinUsdt(res.paymentConf.spotTradeMinUSDT)
        }
        setLoadingPayConf(false)
    }

    // useEffect(() => {
    //     getDataFromCoingecko()
    // }, [])
    //
    // useInterval(() => {
    //     getDataFromCoingecko()
    // }, 300000)

    // const getDataFromCoingecko = async () => {
    //     const res = await axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=200&page=1&sparkline=true&price_change_percentage=1h,24h,7d,14d,30d,200d,1y')
    //     setAllPrices(res.data)
    //     localStorage.setItem(SocketEventsEnum.ALL_PRICES, JSON.stringify(res.data))
    // }

    return (
        <PriceContext.Provider value={{
            allPrices,
            loadingPayConf,
            tetherBuyPrice,
            tetherSellPrice,
            setTetherBuyPrice,
            setTetherSellPrice,
            feePercent,
            investFeePercent,
            setFeePercent,
            depositIRTEnable,
            depositIRTMax,
            depositIRTMin,
            tradeMaxIRT,
            tradeMinIRT,
            withdrawIRTEnable,
            withdrawIRTMax,
            withdrawIRTMin,
            spotTradeMinUsdt,
            spotTradeMaxUsdt,
            feePercentSpot
        }}>
            <Outlet/>
        </PriceContext.Provider>
    )


}

export default PriceProvider;
