import React from "react";

function Icon() {
    return (
        <svg
            style={{display:'block'}}
            width="1.4rem"
            height="1.4em"
            fill="none"
            viewBox="0 0 28 28"
            aria-hidden={"true"}
        >
            <path
                stroke="#D90429"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M10.383 8.82c.362-4.2 2.52-5.915 7.245-5.915h.152c5.215 0 7.303 2.088 7.303 7.303v7.607c0 5.215-2.088 7.303-7.303 7.303h-.152c-4.69 0-6.848-1.691-7.233-5.821M17.5 14H4.223M6.825 10.092L2.916 14l3.909 3.908"
            ></path>
        </svg>
    );
}

export default Icon;
